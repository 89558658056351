module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-piwik-pro/gatsby-browser.js'),
      options: {"plugins":[],"containerUrl":"https://heyqq.containers.piwik.pro","siteId":"f53f1494-cee6-4316-a490-faabb3b6e583","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":200,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://oscarstories.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"oscar-personal-bedtime-stories","short_name":"oscar","start_url":"/","background_color":"#1c1936","theme_color":"#1c1936","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"acee05de30bf8a71fa8abb58d57fe7f0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"redirect":true,"languages":["en","de","es","fr","it","pt","ko"],"defaultLanguage":"en","siteUrl":"https://www.oscarstories.com","i18nextOptions":{"defaultNS":"common","lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/blog/:lang?/:uid","getLanguageFromPath":true},{"matchPath":"/stories/:lang?/:uid","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
